<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2023-09-25 09:31:11
-->
<template>
  <div class="page">
    <div class="logOut" @click="logout">退出登录</div>
    <div class="formed">
      <el-form ref="form"
               :model="form"
               :rules="rules"
               label-width="auto">
        <el-form-item prop="recName"
                      :label="$t('S_C_86')">
          <el-input v-model="form.recName"
                    :placeholder="$t('MN_F_T_746')"></el-input>
        </el-form-item>
        <el-form-item prop="recPhone"
                      :label="$t('MN_F_23')">
          <el-input v-model="form.recPhone"
          maxlength="20"
          oninput="value=value.replace(/[^\d]/g,'')"
                    :placeholder="$t('w_0053')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('w_0068')"
                      class="dizhi"
                      prop="dizhi">
          <el-cascader v-model="form.dizhi"
                       @change="setDizhi"
                       :options="areaData"
                       :props="regionParams"
                       :placeholder="$t('w_0069')"></el-cascader>
        </el-form-item>

        <el-form-item prop="recAddress"
                      :label="$t('S_C_27')">
          <el-input v-model="form.recAddress"
                    :placeholder="$t('w_0070')"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn"
           @click="submit"
           v-loading.fullscreen.lock="fullscreenLoading">查询</div>
    </div>
    <el-dialog :title="$t('MN_F_T_8')"
               :visible.sync="centerDialogVisible"
               width="30%"
               center>
      <el-table :data="tableData"
                ref="mainTable"
                height="480px"
                :header-cell-style="{ background: '#EEEEEE' }"
                :row-class-name="tableRowClassName">
        <el-table-column align="center"
                         prop="memberName"
                         :label="$t('CK_KS_14')">
        </el-table-column>
        <el-table-column align="center"
                         prop="phone"
                         :label="$t('w_0052')">
        </el-table-column>
      </el-table>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="centerDialogVisible = false">{{ $t('MY_ORD_24') }}</el-button>
        <el-button type="warning"
                   :disabled="tableData.length==0"
                   @click="handleExport">{{ $t('MN_T_8') }}</el-button>
      </span>
      <pagination v-show="total>0"
                  :total="total"
                  :page.sync="queryParams.pageNum"
                  :limit.sync="queryParams.pageSize"
                  @pagination="getDataList" />
    </el-dialog>
  </div>
</template>

<script>
import * as api from '@/api/register.js'

export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 50,
      },
      total: 0,
      form: {
        dizhi: [],
      },
      areaData: [],
      regionParams: {
        label: 'name',
        value: 'id',
        children: 'children',
        expandTrigger: 'hover',
      },
      fullscreenLoading: false,
      rules: {
        recName: [
          { required: true, trigger: 'blur', message: this.$t('MN_F_T_746') },
        ],
        recPhone: [
          { required: true, trigger: 'blur', message: this.$t('w_0053') },
        ],
        recAddress: [
          { required: true, trigger: 'blur', message: this.$t('w_0070') },
        ],
        dizhi: [
          { required: true, trigger: 'change', message: this.$t('w_0069') },
        ],
      },
      centerDialogVisible: false,
      tableData: [],
    }
  },
  mounted() {
    this.getAllArea()
  },
  methods: {
    setDizhi() {
      this.form.recProvince = this.form.dizhi[0]
      this.form.recCity = this.form.dizhi[1]
      this.form.recCounty = this.form.dizhi[2] || ''
    },
    getAllArea() {
      api.allArea().then((res) => {
        this.areaData = res.data
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true
          api
            .saveSpecial(this.form)
            .then((res) => {
              if (res.code == 200) {
                // 获取列表
                this.getDataList()
              }
            })
            .catch((err) => {
              this.fullscreenLoading = false
            })
        }
      })
    },
    getDataList() {
      api
        .specialList(
          Object.assign(
            {
              recName: this.form.recName,
              recPhone: this.form.recPhone,
            },
            this.queryParams
          )
        )
        .then((res) => {
          if (res.code == 200) {
            this.fullscreenLoading = false
            this.tableData = res.rows
            this.total = res.total
            this.centerDialogVisible = true
          }
        })
    },
    async logout() {
      this.$confirm(`${this.$t('w_0033')}?`, this.$t('w_0034'), {
        confirmButtonText: this.$t('w_0035'),
        cancelButtonText: this.$t('ENU_P_TYPE0'),
        type: "warning",
      })
        .then(() => {
          localStorage.setItem('pkCountry', '')
          this.$store.dispatch("LogOut").then(() => {
            location.href = "/";
          });
        })
        .catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.$confirm(this.$t('MN_F_T_407'), this.$t('MN_F_T_304'), {
        confirmButtonText: this.$t('w_0035'),
        cancelButtonText: this.$t('ENU_P_TYPE0'),
        type: 'warning',
      }).then((_) => {
        this.download(
          '/member/api/member-special/special-export',
          {
            recName: this.form.recName,
            recPhone: this.form.recPhone,
          },
          `${this.$t('MN_F_T_8')}${new Date().getTime()}.xlsx`
        )
      })
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return 'warning-row'
      } else if (rowIndex % 2 == 0) {
        return 'success-row'
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  background: url('../assets/images/specialMem.png') no-repeat;
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  .formed {
    position: absolute;
    width: 25%;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    top: 30%;
  }
  .btn {
    width: 372px;
    height: 49px;
    background: #d61820;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    color: #fff;
    line-height: 49px;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
  }
}
.dizhi {
  ::v-deep .el-cascader {
    width: 100%;
  }
}
.logOut{
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  padding:5px 10px;
  font-size: 14px;
  background: #d61820;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    color: #fff;
}
</style>